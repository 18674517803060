<template>
  <datetime-picker
    placeholder="選擇時間區間"
    range
    v-model="value"
    hide-details
    dense
    clearable
  ></datetime-picker>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    value: [],
  }),

  computed: {
    filterValue() {
      if (!this.listFilter) return null;
      return this.listFilter.created_at;
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
  },
  watch: {
    filterValue: {
      immediate: true,
      async handler() {
        this.value = this.$eagleLodash.cloneDeep(this.filterValue);
      },
    },
    value: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.value, this.filterValue)) return;
        this.startFilter();
      },
    },
  },
  methods: {
    async startFilter() {
      let filter = { created_at: this.value };
      if (window.eagleLodash.isEqual(this.listFilter, filter)) return;

      const newlistFilter = { ...this.listFilter, ...filter };
      this.$nextTick(() => {
        this.$store.dispatch(`list/${this.listKey}/setFilter`, newlistFilter);
        this.$nextTick(() => {
          this.listActions.changeIndexCondition();
        });
      });
    },
  },
};
</script>
