<template>
  <div>
    <p v-if="eventText">{{ eventText }}</p>
    <v-text-field
      v-show="showSearchBar"
      dense
      outlined
      single-line
      hide-details
      clearable
      @keyup.enter="search"
      @keydown.enter="search"
      @blur="search"
      @click:clear="clearSearch"
      :placeholder="searchPlaceholder"
      prepend-inner-icon="ri-search-line"
      v-model="keyword"
      class="list-search"
      maxlength="20"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    keyword: null,
    eventText: null,
  }),
  computed: {
    showSearchBar() {
      if (!this.hideSearchInput) {
        return true
      }
      return this.searchBarStatus
    },
    searchBarStatus() {
      return this.$store.getters[`list/${this.listKey}/searchBarStatus`];
    },
    hideSearchInput() {
      return this.listConfig.hideSearchInput === true
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    searchKeyword() {
      return this.$store.getters[`list/${this.listKey}/searchKeyword`];
    },
    searchPlaceholder() {
      const defaultPlaceholder = this.$t("action.keyword");
      if (!this.listConfig) return defaultPlaceholder;
      if (typeof this.listConfig.search != "string") return defaultPlaceholder;
      return this.$t(this.listConfig.search);
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
  },
  watch: {
    searchKeyword: {
      immediate: true,
      handler() {
        this.keyword = this.searchKeyword;
      },
    },
  },
  methods: {
    async search() {
      if (this.keyword == this.searchKeyword) return;
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: "searchKeyword",
        value: this.keyword,
      });

      // 從第一頁開始
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: "page",
        value: 1,
      });

      this.listActions.changeIndexCondition();
    },
    async clearSearch() {
      await this.$nextTick();
      await this.search();
    },
    detect(e) {
      this.eventText = e.keyCode
    },
  },
};
</script>
