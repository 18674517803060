<template>
  <section>
    <div>
      <section>
        <!-- info -->
        <component v-if="headerInfo" :is="headerInfo" />

        <div v-if="searchFilterOrder == 0" class="mb-0">
          <v-container v-if="enableSearch" class="py-0 mb-0">
            <liff-list-search class="" :listKey="listKey" />
          </v-container>
          <liff-list-filter :listKey="listKey" />
        </div>

        <div v-if="searchFilterOrder == 1" class="mb-0">
          <liff-list-filter :listKey="listKey" />
          <v-container v-if="enableSearch" class="py-0 mb-0">
            <liff-list-search :listKey="listKey" />
          </v-container>
        </div>

        <div v-if="searchFilterOrder == 2" class="mb-0">
          <liff-list-filter :listKey="listKey" />
          <v-container v-if="enableSearch" class="py-0 mb-0"> </v-container>
        </div>

        <div v-if="searchFilterOrder == 3" class="mb-0">
          <v-container class="pb-0 pt-0">
            <v-btn
              color="primary"
              icon
              x-small
              style="display: block; margin-left: auto;"
              @click="infoShow = !infoShow"
              class="mb-2"
            >
              <v-icon v-if="infoShow">fa-solid fa-chevron-up</v-icon>
              <v-icon v-else>fa-solid fa-chevron-down</v-icon>
            </v-btn>
            <div class="my-0" v-if="infoShow">
              <v-container v-if="enableSearch" class="pa-0 mb-0">
                <liff-list-search class="" :listKey="listKey" />
              </v-container>
              <v-container class="pa-0 my-4">
                <select-date :listKey="listKey" />
              </v-container>
            </div>
          </v-container>
          <liff-list-filter :listKey="listKey" />
        </div>

        <div v-if="sortOrder" class="mb-0">
          <v-container class="py-0 mb-0">
            <div class="d-flex justify-end pt-3 pb-0">
              <span>訂單排序</span>&nbsp;<v-icon>fa fa-bars</v-icon>
            </div>
          </v-container>
        </div>

        <v-container v-if="searchKeyword">
          <div>搜尋結果...</div>
        </v-container>
      </section>

      <!-- data -->
      <v-container class="mb-8 py-0">
        <slot name="list-data-title"></slot>
        <listToolbar
          :list-key="listKey"
          :unprocessedKeys="unprocessedKeys"
        ></listToolbar>

        <template v-if="listData && listData.length > 0">
          <v-row v-if="useRow" no-gutters>
            <v-col
              v-for="(data, index) in listData"
              :key="index"
              v-bind="itemCols"
            >
              <component :is="itemComponent" :listKey="listKey" :data="data" />
            </v-col>
          </v-row>

          <div v-else>
            <v-draggable
              v-if="sortOrder"
              handle=".order-draggable"
              @end="sortEnd"
            >
              <component
                v-for="(data, index) in listData"
                :key="index"
                :is="itemComponent"
                :listKey="listKey"
                :data="data"
              />
            </v-draggable>
            <template v-else>
              <component
                v-for="(data, index) in listData"
                :key="index"
                :is="itemComponent"
                :listKey="listKey"
                :data="data"
              />
            </template>
          </div>
        </template>
        <template v-else>
          <p class="py-2">無相關資料</p>
        </template>
      </v-container>

      <!-- pagination -->
      <v-pagination
        v-if="createPager"
        v-model="page"
        :length="pager.pages"
        :total-visible="6"
        elevation="0"
        circle
        class="mb-8"
      ></v-pagination>
      <div v-if="createPager" style="height: 60px"></div>
      <div>
        <listMetaAction :list-key="listKey"></listMetaAction>
      </div>
    </div>
  </section>
</template>

<script>
import liffListSearch from "@/components/liffList/listSearch/liffListSearch.vue";
import liffListFilter from "@/components/liffList/listFilter/liffListFilter.vue";
import listMetaAction from "@/components/list/listMetaAction.vue";
import listExportExcel from "@/components/list/listExportExcel.vue";
import listToolbar from "@/components/liffList/listToolbar.vue";
import selectDate from "@/components/liffList/selectDate/selectDate.vue";
export default {
  props: {
    listKey: String,
    unprocessedKeys: Array,
  },
  data: () => ({
    infoShow: false,
  }),
  components: {
    liffListSearch,
    liffListFilter,
    listMetaAction,
    listExportExcel,
    listToolbar,
    selectDate,
  },
  computed: {
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`];
    },
    batchConfig() {
      return this.$store.getters[`list/${this.listKey}/batchConfig`];
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`];
    },
    listHasSelected() {
      return this.$store.getters[`list/${this.listKey}/hasSelected`];
    },
    createPager() {
      if (!this.pager) return false;
      return this.pager.pages > 1;
    },
    pager() {
      return this.$store.getters[`list/${this.listKey}/pager`];
    },
    page: {
      get() {
        return this.$store.getters[`list/${this.listKey}/page`];
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: "page",
          value,
        });

        this.listActions.changeIndexCondition(true);
      },
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
    useRow() {
      if (this.listConfig.useRow == undefined) return true;
      return this.listConfig.useRow;
    },
    itemCols() {
      const defaultCols = {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        cols: 12,
      };

      if (this.displayCardMode === "list") {
        return defaultCols;
      }

      if (!this.listConfig) return defaultCols;
      if (!this.listConfig.itemCols) return defaultCols;
      return { ...defaultCols, ...this.listConfig.itemCols };
    },
    displayCardMode() {
      return this.$store.getters[`list/${this.listKey}/displayCardMode`];
    },
    headerInfo() {
      if (!this.listConfig) return null;
      if (!this.listConfig.headerInfo) return null;
      return this.listConfig.headerInfo;
    },
    enableConfig() {
      if (this.listConfig.enable == null) return null;
      return this.listConfig.enable;
    },
    enableSearch() {
      if (this.enableConfig) {
        const { search } = this.enableConfig;
        if (search == null) return true;
        if (typeof search == "function") return search();
        return search;
      }
      return true;
    },
    searchFilterOrder() {
      if (!this.listConfig) return 0;
      if (!this.listConfig.searchFilterOrder) return 0;
      return this.listConfig.searchFilterOrder;
    },
    sortOrder() {
      if (!this.listConfig) return 0;
      if (!this.listConfig.sortOrder) return 0;
      return this.listConfig.sortOrder;
    },
    itemComponent() {
      if (!this.listConfig) return null;
      if (!this.listConfig.listItem) return null;
      return this.listConfig.listItem;
    },
    searchKeyword() {
      return this.$store.getters[`list/${this.listKey}/searchKeyword`];
    },
  },
  methods: {
    sortEnd(e) {
      const emitData = {
        orderId: this.listData[e.oldIndex].order_no,
        delivery_sort: e.newIndex,
      };
      this.$emit("sortEnd", emitData);
    },
  },
};
</script>
