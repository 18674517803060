<template>
  <v-btn
    v-if="exportExcelConfig && dataTotalQuantity > 0"
    small
    depressed
    class="mx-2 white--text"
    color="green"
    :loading="loading"
    @click="exportConfirm"
  >
    <v-icon left>fa fa-file-excel</v-icon>
    <span>{{'action.export'| t}}</span>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import fetchData from '@/components/list/listExportExcel/fetchData'
export default {
  mixins: [fetchData],
  data: () => ({
    sheets: [],
  }),
  methods: {
  },
  computed: {
    sheetsConfig() {
      return this.exportExcelConfig.sheets
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
