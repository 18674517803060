<template>
  <div v-if="listMetaAction">
    <template v-for="(config, metaActionKey) in listMetaAction">
      <component
        v-if="create(config)"
        :key="metaActionKey"
        :config="config"
        :listKey="listKey"
        :is="getComponent(config)"
      ></component>
    </template>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  methods: {
    create(config) {
      if(typeof config.create === 'function') {
        return config.create(this.hasWritePermission)
      }

      return true
    },
    getComponent(config) {
      if(config.component) return config.component
      return 'listMetaButton'
    },
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`list/${this.listKey}/hasWritePermission`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    listMetaAction() {
      return window.eagleLodash.get(this.listConfig, ['metaAction'])
    },
  },
  components: {
    listMetaButton: () => import('@/components/list/listMetaAction/button.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
