import qs from "qs";
import eagleList from "@/components/list/list.vue";
import liffList from "@/components/liffList/list.vue";
export default {
  methods: {
    _serialize(data) {
      return qs.stringify(data, { encode: false });
    },
    _hasModule() {
      return this.$store.getters[`list/${this.listKey}/config`] != undefined;
    },
    _destroyVuexModule() {
      if (this._hasModule() === false) return;
      this.$store.unregisterModule(["list", this.listKey]);
    },
    async scrollListDataTop() {
      await this.$nextTick();
      const $table = $(
        `*[eagle-table="${this.listKey}"] .v-data-table__wrapper`
      );
      $table.animate({
        scrollTop: 0,
      });
      const $listDataTop = $('*[eagle-list="data-top"]')[0];
      this.$vuetify.goTo($listDataTop);
      await this.$helper.delay(3);
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: "scrollTopAfterIndex",
        value: false,
      });
    },
    checkHasWritePermission(listConfig) {
      if (!listConfig) return true;
      if (typeof listConfig.hasWritePermission != "function") return true;
      return listConfig.hasWritePermission((...args) =>
        window.tokenStore.hasRole(...args)
      );
    },
  },
  computed: {
    listMeta() {
      return this.$store.getters[`list/${this.listKey}/meta`];
    },
    scrollTopAfterIndex() {
      return this.$store.getters[`list/${this.listKey}/scrollTopAfterIndex`];
    },
    defaultSortType() {
      return this.$store.getters[`list/${this.listKey}/defaultSortType`];
    },
    defaultSortKey() {
      return this.$store.getters[`list/${this.listKey}/defaultSortKey`];
    },
    sortType() {
      return this.$store.getters[`list/${this.listKey}/sortType`];
    },
    sortKey() {
      return this.$store.getters[`list/${this.listKey}/sortKey`];
    },
    page() {
      return this.$store.getters[`list/${this.listKey}/page`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
    searchKeyword() {
      return this.$store.getters[`list/${this.listKey}/searchKeyword`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    listIndexFilter() {
      return this.$store.getters[`list/${this.listKey}/indexFilter`];
    },
    perPage() {
      return this.$store.getters[`list/${this.listKey}/perPage`];
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    containerFormData() {
      return this.$store.getters[`form/${this.containerFormKey}/data`];
    },
    listRouteQueryHasSort() {
      if (!this.sortKey) return false; // 沒設定允許排序
      if (this.sortKey != this.defaultSortKey) return true; // 排序依據動過
      return this.sortType != this.defaultSortType; // 排序依據沒動過, 但動過排序類型(預設為desc)
    },
    customRouteQuery() {
      let query = window.eagleLodash.cloneDeep(this.$route.query);
      delete query.page;
      delete query.per;
      delete query.sort;
      delete query.search;
      delete query.filter;
      delete query.display;
      if (window.eagleLodash.isEmpty(query)) return null;
      return query;
    },
    parseRouteQuery() {
      let query = window.eagleLodash.cloneDeep(this.$route.query);
      const defaultSort = { [this.defaultSortKey]: this.defaultSortType };
      let result = {
        page: query.page ? parseInt(query.page) : 1,
        per: query.per ? parseInt(query.per) : 15,
        search: !query.search ? null : query.search,
        filter: !query.filter ? null : qs.parse(query.filter),
        sort: !query.sort ? defaultSort : qs.parse(query.sort),
        display: query.display,
      };
      return result;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(after, before) {
        this._onRouteChange(after, before);
      },
    },
  },
  components: {
    eagleList,
    liffList,
  },
};
