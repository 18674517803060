<template>
  <div eagle-list="root" :list-key="listKey">
    <div class="elevation-0 px-4 py-4">
      <!-- 列表搜尋/篩選 -->
      <listSearchAndFilter
        class="my-4"
        :listKey="listKey"
      ></listSearchAndFilter>

      <!-- 列表工具列 -->
      <listToolbar class="my-4" :listKey="listKey"></listToolbar>

      <!-- 列表資訊 -->
      <listInfo class="my-4" :list-key="listKey"></listInfo>

      <div eagle-list="data-top"></div>

      <!-- 卡片模式 -->
      <listCardMode
        class="my-4"
        v-if="listDisplayMode === 'card'"
        :listKey="listKey"
      ></listCardMode>

      <!-- 表格模式 -->
      <listTableMode
        class="my-4"
        v-if="listDisplayMode === 'table'"
        :listKey="listKey"
      ></listTableMode>

      <!-- 分頁 -->
      <v-pagination
        v-if="createPager"
        v-model="page"
        :length="pager.pages"
        :total-visible="6"
      ></v-pagination>
      <div v-if="createPager" style="height: 60px"></div>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  methods: {
    // 設定module selector模式的click行為
    async setupSelectorMode() {
      await this.$helper.delay(0.5);

      const self = this;
      const $listComponentRoot = $(
        `*[eagle-list="root"][list-key="${this.listKey}"]`
      );
      const $tr = $listComponentRoot.find(`tbody tr`);
      $tr.unbind("click");
      if (!this.isPopupMode) {
        return;
      }
      $tr.click(function() {
        const clickRowIndex = $(this).index();
        self.onTrClick(clickRowIndex);
      });
    },
    // table tr點擊callback
    onTrClick(rowIndex) {
      if (!Array.isArray(this.listData)) return;
      const row = this.listData[rowIndex];
      if (!row) return;
      const target = row[this.targetKey];
      const $checkbox = $(`input[list-checkbox="${target}"]`);
      if ($checkbox.length == 0) return;
      const isDisabled = !!$checkbox.attr("disabled");
      if (isDisabled) return; // checkbox disabled
      if (!Array.isArray(this.listSelected)) return;
      const selectedIndex = this.listSelected.findIndex(
        (item) => item[this.targetKey] == target
      ); // 找出點擊的row在已選取陣列(listSelected)中的索引值
      let selectedArray = window.eagleLodash.cloneDeep(this.listSelected);
      const selectedRow = this.getSelectedData(row);

      // // 不存在就加入選取, 存在就移除選取
      if (selectedIndex == -1) selectedArray.push(selectedRow);
      else selectedArray.splice(selectedIndex, 1);

      // 單選模式
      if (
        this.selectorSingleMode &&
        selectedArray.length > this.listSelected.length
      ) {
        const diff = window.eagleLodash.differenceWith(
          selectedArray,
          this.listSelected,
          window.eagleLodash.isEqual
        );
        if (diff.length == 1) selectedArray = diff;
      }
      this.$store.dispatch(`list/${this.listKey}/setSelected`, selectedArray);
    },
    // 取得已選取資料
    getSelectedData(row) {
      const defaultSelectedData = { [this.targetKey]: this.target };
      if (!this.listConfig) return defaultSelectedData;
      if (typeof this.listConfig.selectedData === "function") {
        return this.listConfig.selectedData(row);
      }
      return { [this.targetKey]: this.target };
    },
  },
  computed: {
    selectorSingleMode() {
      return this.$store.getters[`list/${this.listKey}/selectorSingleMode`];
    },
    targetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`];
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    listSelected() {
      return this.$store.getters[`list/${this.listKey}/selected`];
    },
    page: {
      get() {
        return this.$store.getters[`list/${this.listKey}/page`];
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: "page",
          value,
        });

        this.listActions.changeIndexCondition(true);
      },
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
    createPager() {
      if (!this.pager) return false;
      return this.pager.pages > 1;
    },
    pager() {
      return this.$store.getters[`list/${this.listKey}/pager`];
    },
    listDisplayMode() {
      return this.$store.getters[`list/${this.listKey}/displayMode`];
    },
    selected() {
      return this.$store.getters[`list/${this.listKey}/selected`];
    },
    isPopupMode() {
      return this.$store.getters[`list/${this.listKey}/isPopupMode`];
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`];
    },
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        if (!this.isPopupMode) return;
        this.$emit(
          "updatePopupSelectData",
          window.eagleLodash.cloneDeep(this.selected)
        );
      },
    },
    listData: {
      deep: true,
      handler() {
        this.setupSelectorMode();
      },
    },
  },
  components: {
    listInfo: () => import("@/components/list/listInfo"),
    listToolbar: () => import("@/components/list/listToolbar"),
    listSearchAndFilter: () =>
      import("@/components/list/listSearchAndFilter.vue"),
    listCardMode: () => import("@/components/list/listCardMode"),
    listTableMode: () => import("@/components/list/listTableMode"),
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
