<template>
  <div>
    <template v-for="(config, name) in filterConfig">
      <liff-list-filter-item
        v-if="showFilter(config)"
        :key="name"
        :listKey="listKey"
        :name="name"
        :config="config"
        :setupItemsCallback="setupItemsCallback"
      ></liff-list-filter-item>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  components: {
    "liff-list-filter-item": () =>
      import("@/components/liffList/listFilter/liffListFilterItem.vue"),
  },
  data: () => ({
    setupItems: [],
  }),
  computed: {
    filterConfig() {
      return this.$store.getters[`list/${this.listKey}/filterConfig`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
    hasFilterFeature() {
      if (!this.listConfig) return false;
      return !!this.listConfig.filter;
    },
  },
  created() {
    this.$root.$on(`start-liff-list-filter`, this.startFilter);
  },
  beforeDestroy() {
    this.$root.$off(`start-liff-list-filter`, this.startFilter);
  },
  methods: {
    showFilter(config) {
      if (typeof config.create == "function") {
        return config.create();
      }
      return true;
    },
    setupItemsCallback(cb) {
      this.setupItems.push(cb);
    },
    startFilter() {
      let filter = {};
      this.setupItems.forEach((config) => {
        config.callback(filter);
      });

      // 測試：防止filter為空物件
      if (Object.keys(filter).length == 0) {
        filter = null;
      }

      if (window.eagleLodash.isEqual(this.listFilter, filter)) return;

      const newlistFilter = { ...this.listFilter, ...filter };
      this.$nextTick(() => {
        this.$store.dispatch(`list/${this.listKey}/setFilter`, newlistFilter);
        this.$nextTick(() => {
          this.listActions.changeIndexCondition();
        });
      });
    },
  },
};
</script>
