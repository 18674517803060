import XLSX from 'xlsx'
const BASE_HEIGHT = 18
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    page: 1,
    pager: null,
    rawData: [],
    loading: false,
  }),
  methods: {
    exportConfirm() {
      if(this.exportExcelConfig.withoutConfirm === true) {
        this.startExport()
        return
      }

      this.$apopup.base({
        title: this.$t('export_excel.confirm'),
        content: this.$t('pager.total', { total: this.dataTotalQuantity }),
        applyCallback: this.startExport,
      })
    },
    resetAll() {
      this.rawData = []
      this.sheets = []
      this.page = 1
      this.pager = null
    },
    async handleXlsx() {
      for(const sheet of this.sheetsConfig) {
        await this.handleSheet(sheet)
      }

      let filename = null
      try {
        filename = await this.setupXlsxWorkbook()
        this.$snotify.success(filename, this.$t('export_excel.successfully'))
      } catch (error) {
        console.error(error)
        this.$snotify.error(this.$t('error.unexpect'), this.$t('export_excel.failure'))
      }

      this.resetAll()

      // 自訂成功後執行的callback
      if(typeof this.exportExcelConfig.successCallback === 'function') {
        this.exportExcelConfig.successCallback(filename)
      }
    },
    // 使用aoa匯出Excel
    async setupXlsxWorkbook() {
      const workbook = XLSX.utils.book_new()

      // 依序處理每個sheet
      for(const sheet of this.sheets) {
        const xlsxSheet = XLSX.utils.aoa_to_sheet(sheet.aoa)
        xlsxSheet['!rows'] = []
        xlsxSheet['!cols'] = []

        for(let i=1; i<=sheet.aoa.length; i++) {
          xlsxSheet['!rows'].push({ hpx: BASE_HEIGHT })
        }

        for(let i=1; i<=sheet.columnQuantity; i++) {
          const width = sheet.widthArray[i-1]
          xlsxSheet['!cols'].push({ wch: width })
        }

        if(sheet.sheetLabel.length > 32) {
          console.warn(`sheet名稱超過31字元將造成xlsx.js無法匯出`, filename)
        }
        XLSX.utils.book_append_sheet(workbook, xlsxSheet, sheet.sheetLabel)
      }
      let filename = this.$t('action.export')
      if(typeof this.exportExcelConfig.filename === 'function') {
        const time = this.$helper.now('YYYYMMDD_HHmmss')
        filename = this.exportExcelConfig.filename(time)
      }
      XLSX.writeFile(workbook, `${filename}.xlsx`)
      return `${filename}.xlsx`
    },
    // 處理每個sheet
    handleSheet(sheet) {
      const sheetLabel = this.$t(sheet.label)
      const aoaHeaders = this.getSheetAoaHeaders(sheet)
      const prependAoa = this.getPrependAoa(sheet)
      const aoa = [
        ...prependAoa,
        aoaHeaders,
        ...this.getSheetAoaData(sheet),
      ]
      const widthArray = []
      for(const dataKey in sheet.data) {
        const dataConfig = sheet.data[dataKey]
        const columnWidth = dataConfig.width || 14
        widthArray.push(columnWidth)
      }

      this.sheets.push({
        widthArray,
        sheetLabel,
        aoa,
        columnQuantity: aoaHeaders.length,
      })
    },
    // 取得sheet的aoa header之前的aoa
    getPrependAoa(sheet) {
      if(typeof sheet.prependAoa != 'function') return []
      const prependAoa = sheet.prependAoa(this.rawData)
      if(!Array.isArray(prependAoa)) return []
      return prependAoa
    },
    // 取得sheet的aoa header
    getSheetAoaHeaders(sheet) {
      const headers = []
      for(const dataKey in sheet.data) {
        const dataConfig = sheet.data[dataKey]
        headers.push(this.$t(dataConfig.label))
      }
      return headers
    },
    // 取得sheet的aoa 內容
    getSheetAoaData(sheet) {
      const aoa = []
      this.iterateRawData((row, index, no) => {
        const aoaRow = []
        for(const dataKey in sheet.data) {
          const dataConfig = sheet.data[dataKey]
          const cell = this.getCellData(dataKey, dataConfig, row, index, no)
          aoaRow.push(cell)
        }
        aoa.push(aoaRow)
      })
      return aoa
    },
    cleanupData() {
      this.rawData = []
    },
    async startExport() {
      this.cleanupData()
      this.loading = true
      try {
        await this.fetchData()
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('export_excel.error.index_failure'),
          this.$t('export_excel.failure')
        )
        this.loading = false
        return
      }

      await this.handleXlsx()
      this.loading = false
    },
    async fetchData() {
      const result = await this.listApi.exportApi(this.exportParams)
      this.setResult(result)
      await this.$nextTick()

      if(this.hasNext) {
        this.page++
        await this.fetchData()
      }
    },
    setResult(result) {
      const data = this.exportExcelConfig.getData ? this.exportExcelConfig.getData(result) : result.data;
      if(result.pager) {
        this.pager = result.pager
        this.rawData = this.rawData.concat(data)
      }

      else {
        this.pager = null
        this.rawData = this.rawData.concat(data)
      }
    },
    // 透過資料設定, row等相關資訊取得每個欄位的資料
    getCellData(dataKey, dataConfig, row, index, no) {
      if(typeof dataConfig.value === 'function') {
        return dataConfig.value(row, index, no)
      }

      return window.eagleLodash.get(row, [dataKey])
    },
    // 迭代資料
    iterateRawData(callback) {
      for(const index in this.rawData) {
        const row = this.rawData[index]
        const no = (parseInt(index)+1)
        callback(row, index, no)
      }
    }
  },
  computed: {
    hasNext() {
      if(!this.pager) return false
      return this.pager.has_next == true
    },
    exportParams() {
      const params = window.eagleLodash.cloneDeep(this.indexParams)
      params.exportMode = true

      if(this.listPager) {
        params.page = this.page
        params.per = this.exportExcelConfig.indexPerPage || 20
      }

      else {
        params.pager = false
      }
      return params
    },
    indexParams() {
      return this.$store.getters[`list/${this.listKey}/indexParams`]
    },
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    listPager() {
      return this.$store.getters[`list/${this.listKey}/pager`]
    },
    dataTotalQuantity() {
      return this.$store.getters[`list/${this.listKey}/dataTotalQuantity`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    exportExcelConfig() {
      return this.$store.getters[`list/${this.listKey}/exportExcelConfig`]
    },
  },
}
