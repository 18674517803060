<template>
  <v-toolbar
    v-if="listToolbarShow && (createSelectAllButton || (hasData && batchConfig) || (multipleDisplayCardMode && tokenRole == 'provider'))"
    flat dense class="d-flex justify-space-between">
    <div>
      <!-- 全選/取消全選 -->
      <v-btn v-if="createSelectAllButton" @click="toggleSelectAll" small outlined class="mr-2" color="primary">
        <span v-if="!selectAllButtonSwitchCheck">{{ 'action.select_all' | t }}</span>
        <span v-if="selectAllButtonSwitchCheck">{{ 'action.cancel_select_all' | t }}</span>
      </v-btn>

      <!-- 批次操作 -->
      <batch v-if="hasData" :list-key="listKey"></batch>
    </div>

    <div v-if="multipleDisplayCardMode && tokenRole == 'provider'">
      <v-btn-toggle class="ml-4" dense :value="0">
        <v-btn color="primary" @click="setDisplayCardMode('normal')">
          圖示
        </v-btn>

        <v-btn color="primary" @click="setDisplayCardMode('list')">
          條列
        </v-btn>
      </v-btn-toggle>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    listKey: String,
    unprocessedKeys: Array,
  },
  methods: {
    setDisplayCardMode(mode) {
      this.$store.commit(`list/${this.listKey}/setDisplayCardMode`, mode)
    },
    toggleSelectAll() {
      // 已全選, 取消全選
      if ("listSelectLimitType" in this.listConfig && this.hasSelected) {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
        return
      } else if (this.hasSelectedAll) {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
        return

      }

      // 未全選, 執行全選
      let list = this.listData
      if ("listSelectLimitType" in this.listConfig) {
        
        list = list.filter((e) => {
          const selectLimitTypeObj = this.listConfig.listSelectLimitType
          const failResArr = []
          for (const key in selectLimitTypeObj) {
            const limitType = selectLimitTypeObj[key]
            if (key in e && typeof (e[key]) != limitType && !this.unprocessedKeys.includes(key)) {
              failResArr.push(key)
            }
          }
          if (failResArr.length == 0) {
            return true
          } else {
            return false
          }
        })
      }
      const selected = list.map(row => {
        if (typeof this.listConfig.selectedData === 'function') {
          return this.listConfig.selectedData(row)
        }
        return { [this.targetKey]: row[this.targetKey] }
      })
      this.$store.dispatch(`list/${this.listKey}/setSelected`, selected)
    },
  },
  computed: {
    selectAllButtonSwitchCheck() {
      let res = false
      if ("listSelectLimitType" in this.listConfig && this.hasSelected) {
        res = true
      } else if (this.hasSelectedAll) {
        res = true
      }
      return res
    },
    createSelectAllButton() {
      if (!this.hasData) return false
      if (!this.batchConfig) return false
      // if(this.isPopupMode) return false // module selector模式不使用全選toggle功能
      return true
    },
    batchConfig() {
      return this.$store.getters[`list/${this.listKey}/batchConfig`]
    },
    usedListEmpty() {
      return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
    },
    tokenRole() {
      return this.$store.getters['token/tokenRole']
    },
    isPopupMode() {
      return this.$store.getters[`list/${this.listKey}/isPopupMode`]
    },
    selectorSingleMode() {
      return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
    },
    hasData() {
      return this.$store.getters[`list/${this.listKey}/hasData`]
    },
    listToolbarShow() {
      return this.$store.getters[`list/${this.listKey}/listToolbarShow`]
    },
    targetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    hasSelected() {
      return this.$store.getters[`list/${this.listKey}/hasSelected`]
    },
    hasSelectedAll() {
      return this.$store.getters[`list/${this.listKey}/hasSelectedAll`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    multipleDisplayCardMode() {
      return this.listConfig.multipleDisplayCardMode === true
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
  },
  components: {
    batch: () => import('@/components/list/listToolbar/batch.vue'),
  },
}
</script>